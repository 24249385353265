import redux from '@doctoroncallcom/redux';
import { createSelector } from 'reselect';

const service = 'videoConsultation';
const rootReducerKey = 'videoConsultation';
const initialState = {
  rtc: {
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
  },
  consultationInStorage: {
    consultationId: null,
  },
  patientPreference: {
    callPreferenceSetByPatient: false,
    modeSelected: 'Video',
  },
};

const constants = {
  SET_RTC_CLIENT: redux.helpers.createConstant(
    service,
    'SET_RTC_CLIENT',
    process.env.REACT_APP_APP_NAME
  ),
  SET_RTC_LOCAL_AUDIO_TRACK: redux.helpers.createConstant(
    service,
    'SET_RTC_LOCAL_AUDIO_TRACK',
    process.env.REACT_APP_APP_NAME
  ),
  SET_RTC_LOCAL_VIDEO_TRACK: redux.helpers.createConstant(
    service,
    'SET_RTC_LOCAL_VIDEO_TRACK',
    process.env.REACT_APP_APP_NAME
  ),
  RESET_VIDEO_CONSULTATION_DATA: redux.helpers.createConstant(
    service,
    'RESET_VIDEO_CONSULTATION_DATA',
    process.env.REACT_APP_APP_NAME
  ),
  SET_PATIENT_CALL_PREFERENCE: redux.helpers.createConstant(
    service,
    'SET_PATIENT_CALL_PREFERENCE',
    process.env.REACT_APP_APP_NAME
  ),
  SET_CONSULTATION_ID: redux.helpers.createConstant(
    service,
    'SET_CONSULTATION_ID',
    process.env.REACT_APP_APP_NAME
  ),
};
const actions = {
  setRTCVideoClient: (data) =>
    redux.helpers.createAction(constants.SET_RTC_CLIENT, { data }),
  setRTCLocalAudioTrack: (data) =>
    redux.helpers.createAction(constants.SET_RTC_LOCAL_AUDIO_TRACK, { data }),
  setRTCLocalVideoTrack: (data) =>
    redux.helpers.createAction(constants.SET_RTC_LOCAL_VIDEO_TRACK, { data }),
  resetRTCData: () =>
    redux.helpers.createAction(constants.RESET_VIDEO_CONSULTATION_DATA),
  setPatientCallPreference: (data) =>
    redux.helpers.createAction(constants.SET_PATIENT_CALL_PREFERENCE, { data }),
  setConsultationId: (data) =>
    redux.helpers.createAction(constants.SET_CONSULTATION_ID, { data }),
};
const reducer = redux.helpers.createReducer(
  {
    [constants.SET_RTC_CLIENT]: (draft, action) => ({
      rtc: {
        ...draft.rtc,
        client: action.payload.data,
      },
    }),
    [constants.SET_RTC_LOCAL_AUDIO_TRACK]: (draft, action) => ({
      rtc: {
        ...draft.rtc,
        localAudioTrack: action.payload.data,
      },
    }),
    [constants.SET_RTC_LOCAL_VIDEO_TRACK]: (draft, action) => ({
      rtc: {
        ...draft.rtc,
        localVideoTrack: action.payload.data,
      },
    }),
    [constants.RESET_VIDEO_CONSULTATION_DATA]: () => ({
      rtc: { ...initialState.rtc },
    }),
    [constants.SET_PATIENT_CALL_PREFERENCE]: (draft, action) => ({
      patientPreference: {
        ...draft.patientPreference,
        callPreferenceSetByPatient: action.payload.data.isPreferenceSelected,
        modeSelected: action.payload.data.mode,
      },
    }),
    [constants.SET_CONSULTATION_ID]: (draft, action) => ({
      consultationInStorage: {
        ...draft.consultationInStorage,
        consultationId: action.payload.data,
      },
    }),
  },
  initialState
);

const selectCallState = (state) => state[rootReducerKey];
const selectors = {
  makeSelectRTCClient: () =>
    createSelector([selectCallState], (state) => state.rtc.client),
  makeSelectRTCLocalAudioTrack: () =>
    createSelector([selectCallState], (state) => state.rtc.localAudioTrack),
  makeSelectRTCLocalVideoTrack: () =>
    createSelector([selectCallState], (state) => state.rtc.localVideoTrack),
  makeSelectIsPatientCallPreferenceSelected: () =>
    createSelector(
      [selectCallState],
      (state) => state.patientPreference.callPreferenceSetByPatient
    ),
  makeSelectPatientCallSelectedMode: () =>
    createSelector(
      [selectCallState],
      (state) => state.patientPreference.modeSelected
    ),
  makeSelectConsultationId: () =>
    createSelector(
      [selectCallState],
      (state) => state.consultationInStorage.consultationId
    ),
};

export { actions, reducer, selectors, rootReducerKey, constants };
