const routes = {
  home: '/',
  doctorProfile: '/doctor/profile',
  doctorLogin: '/doctor/login',
  doctorForgotPassword: '/doctor/forgot-password',
  doctorVerifyEmail: '/doctor/verify-email',
  doctorResetPassword: '/doctor/reset-password',
  consultationHistoryList: '/consultation/history-list',
  consultationOverview: '/consultation/overview',
  appointmentHistoryList: '/appointment/history-list',
  appointmentOverview: '/appointment/overview',
  doctorConsultation: '/doctor/consultation/*',
};
export default routes;
