import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';

const ConsultationOverview = React.lazy(() =>
  import('../../views/ConsultationOverview')
);

const params = [];
const ConsultationOverviewRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout>
        <ConsultationOverview />
      </Layout>
    </RouteParameter>
  );
};

ConsultationOverviewRoute.propTypes = {};

ConsultationOverviewRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ConsultationOverviewRoute);
