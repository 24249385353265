import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as redux from '@doctoroncallcom/redux';
import * as reduxInternal from '../../redux/index';
import api from '../../utils/api';
import URLBuilder from '../../utils/url';
import { SSO_URL } from '../../config/constants';
import FullPageLoader from '../../components/Loader/fullPageLoader';

const direct = () => {
  window.location.href = new URLBuilder(SSO_URL)
    .replaceParams({
      redirectUri: `${window.location.href}`,
    })
    .getUrl();
};
const SSO = ({
  token,
  children,
  dispatch,
  profile,
  profileLoading,
  profileError,
}) => {
  // const params = new URLBuilder().getParams();
  // const authToken = params.token || token;
  let tokenParam = null;
  if (window.location.href.includes('?token=')) {
    // eslint-disable-next-line prefer-destructuring
    tokenParam = window.location.href.split('?token=')[1];
  }
  const authToken = tokenParam || token;

  useEffect(() => {
    api.setAuthorizationToken(authToken);
    dispatch(redux.user.actions.getProfile());
  }, [authToken]);

  useEffect(() => {
    if (profile?.id) {
      dispatch(reduxInternal.persist.actions.setToken(authToken));
      // correct method below is commented temporarily
      // if (params.token) {
      //   const newURL = new URL(window.location);
      //   const urlParams = new URLSearchParams(newURL.search) || {};
      //   window.location.href = new URLBuilder(newURL.origin, urlParams)
      //     .removeParam('token')
      //     .getUrl();
      // }
      if (window.location.href.includes('?token=')) {
        // eslint-disable-next-line prefer-destructuring
        window.location.href = window.location.href.split('?token=')[0];
      }
    }
  }, [profile?.id]);

  useEffect(() => {
    if (profileError) {
      if (profileError.code === 401) {
        // eslint-disable-next-line no-console
        console.log('token expired');
        localStorage.clear();
        direct();
      }
    }
  }, [profileError]);

  if (!authToken && !profileLoading) {
    direct();
  } else if (profile?.id) {
    return children;
  }

  return <FullPageLoader />;
};

SSO.propTypes = {
  token: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  profileLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  profileError: PropTypes.any,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

SSO.defaultProps = {
  token: undefined,
  children: undefined,
  profileError: null,
};

const mapStateToProps = (state) => ({
  token: reduxInternal.persist.selectors.token()(state),
  profile: redux.user.selectors.profile()(state),
  profileLoading: redux.user.selectors.profileLoading()(state),
  profileError: redux.user.selectors.profileError()(state),
});

export default connect(mapStateToProps)(SSO);
