import React, { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ToggleMenuContextProvider } from './ToggleMenuContext';
import Header from '../Header';
import FullPageLoader from '../../components/Loader/fullPageLoader';
import './style.css';

const Layout = ({ children }) => {
  const { height } = useWindowDimensions();
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(true);
  const [showSideBar, setShowSideBar] = useState(true);
  const toggleMenu = (e) => {
    e.preventDefault();
    setShowHeader(!showHeader);
  };

  const checkHeaderToShow = () => {
    if (
      window.location.pathname === '/doctor/login' ||
      window.location.pathname === '/doctor/forgot-password' ||
      window.location.pathname === '/doctor/verify-email' ||
      window.location.pathname === '/doctor/reset-password' ||
      window.location.pathname === '/doctor/consultation/consultation-room' ||
      window.location.pathname ===
        '/doctor/consultation/consultation-room-audio'
    ) {
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  };

  useEffect(() => {
    checkHeaderToShow();
  }, [window.location.pathname, location]);

  useEffect(() => {
    checkHeaderToShow();
    if (window.innerWidth <= 768) {
      setShowHeader(false);
    }
  }, []);

  return (
    <div className="w-full flex" style={{ height }}>
      {showSideBar ? (
        <Header toggleMenu={toggleMenu} showHeader={showHeader} />
      ) : null}
      <Suspense fallback={<FullPageLoader />}>
        <div
          className={`${showSideBar ? 'content-con' : ''}  ${
            !showSideBar ? 'w-full' : ''
          }`}
        >
          <ToggleMenuContextProvider
            value={{
              toggleMenu,
            }}
          >
            {children}
          </ToggleMenuContextProvider>
        </div>
      </Suspense>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

Layout.defaultProps = {
  children: undefined,
};
export default Layout;
