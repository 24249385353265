export const ENV =
  window.location.href.includes('dev') ||
  window.location.href.includes('localhost')
    ? '.dev'
    : '';

export const REACT_APP_SSO_URL = `https://auth${ENV}.doctoroncall.com.my`;
export const MARKETPLACE_INVENTORY_URL =
  ENV === '.dev'
    ? `https://www.uat.doctoroncall.com/marketplace/api/market/platforminventory?SECRET_KEY=this_api_not_for_you_cc3477ed-d8a6-41e2-87cc-5ad0f5f39a6c_this_api_not_for_you`
    : `https://www.doctoroncall.com/marketplace/api/market/platforminventory?SECRET_KEY=this_api_not_for_you_98ad6c7f-950f-4b40-8a6c-458e3d33a181_this_api_not_for_you`;
