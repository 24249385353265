import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';

const Consultation = React.lazy(() => import('../../views/Consultation'));

const params = [];
const ConsultationRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout>
        <Consultation />
      </Layout>
    </RouteParameter>
  );
};

ConsultationRoute.propTypes = {};

ConsultationRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ConsultationRoute);
