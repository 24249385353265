import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuButton from '@doctoroncallcom/dermis-web/dist/esm/MenuButton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import URL from '../../utils/url';
import { SSO_LOGOUT_URL } from '../../config/constants';
import './style.css';

const classNames = require('classnames');

const Header = ({ toggleMenu, showHeader }) => {
  const navigate = useNavigate();

  const navigateToLink = (url) => {
    navigate(url);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = new URL(SSO_LOGOUT_URL).getUrl();
  };

  return (
    <div
      className={classNames('h-screen fixed menu_con', {
        'menu-backdrop-show': showHeader,
        'menu-backdrop-hide': !showHeader,
      })}
    >
      <div className="backdrop" onClick={toggleMenu}>
        &nbsp;
      </div>
      <div
        className={classNames('w-full h-screen fixed z-10 header', {
          'header-show': showHeader,
          'header-hide': !showHeader,
        })}
      >
        <div className="p-10">
          <img
            src="/assets/icons/DOC-LOGO.svg"
            alt="DoC Logo"
            width="100px"
            onClick={() => navigateToLink('/')}
          />
        </div>
        <div className="py-6 menu_con z-10">
          <nav>
            <MenuButton
              label="Dashboard"
              type="button"
              normal={window.location.pathname !== '/'}
              href="/"
              width="100%"
              height="56px"
              icon="dashboard"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/')}
            />
            <MenuButton
              label="Consultations"
              type="button"
              normal={window.location.pathname !== '/consultation/history-list'}
              href="/consultation/history-list"
              width="100%"
              height="56px"
              icon="consultation-history"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/consultation/history-list')}
            />
            <MenuButton
              label="Appointments"
              type="button"
              normal={window.location.pathname !== '/appointment/history-list'}
              href="/appointment/history-list"
              width="100%"
              height="56px"
              icon="customers"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/appointment/history-list')}
            />
            <MenuButton
              label="My Profile"
              type="button"
              normal={window.location.pathname !== '/doctor/profile'}
              href="/doctor/profile"
              width="100%"
              height="56px"
              icon="profile"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/doctor/profile')}
            />
            {/* <MenuButton */}
            {/*  label="Settings" */}
            {/*  type="button" */}
            {/*  normal={window.location.pathname !== '/settings'} */}
            {/*  href="/settings" */}
            {/*  width="100%" */}
            {/*  height="56px" */}
            {/*  icon="settings" */}
            {/*  relativeImgPath="/assets/icons/menu-icons/" */}
            {/*  // onClick={() => navigateToLink('/settings')} */}
            {/* /> */}
            <MenuButton
              label="Log Out"
              type="button"
              normal={true}
              href="#"
              width="100%"
              height="56px"
              icon="log-out"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={handleLogout}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  toggleMenu: PropTypes.func,
  showHeader: PropTypes.bool,
};

Header.defaultProps = {
  toggleMenu: undefined,
  showHeader: false,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Header);
