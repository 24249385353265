import { combineReducers } from 'redux';
import {
  user,
  fileDomain,
  consultations,
  appointments,
  alerts,
  prescriptions,
  chat,
  otp,
  trustgate,
  icd,
} from '@doctoroncallcom/redux';
import { session, persist, videoConsultation, rtm } from '../redux/index';

const injectedReducers = {};

export default combineReducers({
  [session.rootReducerKey]: session.reducer,
  [user.rootReducerKey]: user.reducer,
  [fileDomain.rootReducerKey]: fileDomain.reducer,
  [alerts.rootReducerKey]: alerts.reducer,
  [consultations.rootReducerKey]: consultations.reducer,
  [appointments.rootReducerKey]: appointments.reducer,
  [persist.rootReducerKey]: persist.reducer,
  [prescriptions.rootReducerKey]: prescriptions.reducer,
  [icd.rootReducerKey]: icd.reducer,
  [videoConsultation.rootReducerKey]: videoConsultation.reducer,
  [rtm.rootReducerKey]: rtm.reducer,
  [chat.rootReducerKey]: chat.reducer,
  [otp.rootReducerKey]: otp.reducer,
  [trustgate.rootReducerKey]: trustgate.reducer,
  ...injectedReducers,
});
