import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../config/routes';
import DoctorProfileRoute from './DoctorProfile';
import DoctorLoginRoute from './DoctorLogin';
import DoctorForgotPasswordRoute from './DoctorForgotPassword';
import DoctorVerifyEmailRoute from './DoctorVerifyEmail';
import DoctorResetPasswordRoute from './DoctorResetPassword';
import ConsultationHistoryListRoute from './ConsultationHistoryList';
import ConsultationOverviewRoute from './ConsultationOverview';
import AppointmentHistoryListRoute from './AppointmentHistoryList';
import AppointmentOverviewRoute from './AppointmentOverview';
import DashboardRoute from './Dashboard';
import ConsultationRoute from './Consultation';

const Routing = () => (
  <Routes>
    <Route path={routes.home} element={<DashboardRoute />} />
    <Route path={routes.doctorProfile} element={<DoctorProfileRoute />} />
    <Route path={routes.doctorLogin} element={<DoctorLoginRoute />} />
    <Route
      path={routes.doctorForgotPassword}
      element={<DoctorForgotPasswordRoute />}
    />
    <Route
      path={routes.doctorVerifyEmail}
      element={<DoctorVerifyEmailRoute />}
    />
    <Route
      path={routes.doctorResetPassword}
      element={<DoctorResetPasswordRoute />}
    />
    <Route
      path={routes.consultationHistoryList}
      element={<ConsultationHistoryListRoute />}
    />
    <Route
      path={routes.consultationOverview}
      element={<ConsultationOverviewRoute />}
    />
    <Route
      path={routes.appointmentHistoryList}
      element={<AppointmentHistoryListRoute />}
    />
    <Route
      path={routes.appointmentOverview}
      element={<AppointmentOverviewRoute />}
    />
    <Route path={routes.doctorConsultation} element={<ConsultationRoute />} />
  </Routes>
);

export default Routing;
