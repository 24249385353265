import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';

const ConsultationHistoryList = React.lazy(() =>
  import('../../views/ConsultationHistoryList')
);

const params = [];
const ConsultationHistoryListRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout>
        <ConsultationHistoryList />
      </Layout>
    </RouteParameter>
  );
};

ConsultationHistoryListRoute.propTypes = {};

ConsultationHistoryListRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ConsultationHistoryListRoute);
